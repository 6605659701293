import React from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import { TypographyWithDangling } from '../../../../UI/beeline/TypographyWithDangling/TypographyWithDangling';
import { DialogModal } from '../../../../UI/beeline/Dialogs/DialogModal';
import { senderNameStore } from '../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';

const ConfirmationDialog = observer(({ sendFormData }: { sendFormData: () => Promise<void> }) => {
  const onCancel = async () => {
    senderNameStore.agreementModal = false;
    senderNameStore.loading = false;
    await navigate('/sender-names');
  };

  const agreementHandler = async () => {
    await senderNameStore.verificationSection();
    senderNameStore.agreementModal = false;
    senderNameStore.loading = false;
    await navigate('/sender-names');
  };
  return (
    <DialogModal
      onSuccess={agreementHandler}
      open={senderNameStore.agreementModal}
      onClose={onCancel}
      title="Имя отправлено на согласование"
      confirmLabel="Хорошо"
      isSingleBtn
      isLoading={senderNameStore.isLoading}
      footer
      >
      <TypographyWithDangling variant="body2">
        Срок согласования 1 рабочий день. О результате согласования вам придет уведомление на почту.
      </TypographyWithDangling>
    </DialogModal>
  );
},
);

export default ConfirmationDialog;
