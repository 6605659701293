import { Button, FileUploader, Icon, InlineAlert, TextArea } from '@beeline/design-system-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { extendObservable, isObservableArray, observable } from 'mobx';
import { createPortal } from 'react-dom';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import sideSheetStore from 'store/mobx/SideSheetStore';
import SideSheet from 'redesignSrc/components/SideSheet';
import NewOrder from 'store/mobx/NewOrder';
import { DocsByBusinessList, FilesById, groupFilesById, senderNameStore as senderNameStoreOld, senderNameStore } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import { toastErrorNotification } from 'modules/toast-notifications';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { composeArrayRequiredValidator, composeLengthValidator, composeOrderSenderNameRegexpValidator, composeRequiredValidator, composeSlugValidator } from '../../../../../../../utils/fieldValidators';
import useValidation, { VALID } from '../../../../../../HOC/hooks/useValidation';
import UserInfo from '../../../../../../../store/mobx/UserInfo';
import styles from './styles.pcss';
import UploadedFiles from './components/UploadedFiles';
import SwitchField from './components/SwitchField';
import SenderNameText from './components/SenderNameText';
import MoreInfoScreen from './components/MoreInfoScreen';
import SenderNamesFileList from './components/SenderNameFileList';
import SelectField from './components/SelectField';

const cx = classNames.bind(styles);

export type SenderNameContextType = {
  validation: VALID,
  senderNameId: string | undefined,
  isIp: boolean,
  setIsIp:(value: boolean) => void,
  onAddFiles: (fileList: FileList, id?: number | string) => void,
  chooseDocsIsVisible: boolean,
  isInfoVisited: boolean,
  setIsInfoVisited: (value: boolean) => void,
  setChooseDocsVisible: (value: boolean) => void,
  onFileEdit: (fileId: string, data: { customFileName: string, documentTypeName: string, documentTypeId: string }) => void,
  onReplaceFile: (id: string,
  idx: number,
  file: FileList | null,
  existFile?: Omit<FilesById, 'file'>) => void,
  onDeleteFile: (idx: FilesById, id: number | string) => void,
  checkIfSenderNameExist: (val: string, ban: number | undefined) => Promise<number>
}

export const SenderNameContext = createContext<SenderNameContextType | null>(null);

export const CreateSenderName = observer(({
  senderNameId,
  children,
}: {
  senderNameId?: string,
  children: React.ReactNode,
}) => {
  const [isIp, setIsIp] = useState(false);
  const [chooseDocsIsVisible, setChooseDocsVisible] = useState(true);
  const [isInfoVisited, setIsInfoVisited] = useState(false);
  const rules = {
    senderName: [
      composeRequiredValidator('Обязательное поле'),
      composeSlugValidator('Должна быть хотя бы одна буква'),
      composeLengthValidator('Длина имени должна быть от 2 до 11 символов', { min: 2, max: 11 }),
      composeOrderSenderNameRegexpValidator('Только латинские буквы, цифры и символы «-», «_», «.».'),
    ],
    files: [composeArrayRequiredValidator('Обязательное поле')],
  };
  const validation = useValidation({
    senderName: senderNameStore.form?.senderName,
    files: Object.keys(senderNameStore?.userFilesById || {}),
  }, rules);

  useEffect(() => {
    if (!Object.keys(senderNameStore?.userFilesById || {}).length) {
      setChooseDocsVisible(true);
    }
  }, [Object.keys(senderNameStore?.userFilesById || {}).length]);

  const onAddFiles = (fileList: FileList, id?: number | string) => {
    if (fileList) {
      Array.from(fileList).forEach(file => {
        if (!senderNameStore.isAllowMimeType(file)) {
          toastErrorNotification(`Неверный формат файла ${file.name}`);
          setChooseDocsVisible(true);
          return;
        }
        const fileId = id || senderNameStore.form?.documentType && (senderNameStore.form?.documentType as DocsByBusinessList[])[0]?.id;
        const documentTypeName = senderNameStore.docsByBusinessType?.find(item => item.id === fileId)?.documentTypeName || '';
        if (
          fileId &&
          senderNameStore?.userFilesById &&
          senderNameStore?.userFilesById[`${fileId}`] &&
          isObservableArray(senderNameStore?.userFilesById[`${fileId}`])
        ) {
          senderNameStore!.userFilesById[`${fileId}`].push({
            documentTypeId: fileId as number,
            documentTypeName,
            customFileName: file.name,
            file,
          });
          setChooseDocsVisible(false);
        } else if (fileId && !senderNameStore?.userFilesById?.[`${fileId}`]) {
          if (!senderNameStore.userFilesById) {
            extendObservable(senderNameStore, { userFilesById: observable.object({}) });
          }
          extendObservable(senderNameStore.userFilesById as unknown as object, { [`${fileId}`]: observable.array([]) });
          if (senderNameStore?.userFilesById?.[`${fileId}`]) {
            senderNameStore.userFilesById?.[`${fileId}`].push({
              documentTypeId: fileId as number,
              documentTypeName: (senderNameStore.form?.documentType as { value: string }[])[0]?.value,
              customFileName: file.name,
              file,
            });
          }
          setChooseDocsVisible(false);
        }
      });
    }
    senderNameStore!.form!.documentType = null;
  };

  const onReplaceFile = async (
    id: string,
    idx: number,
    file: FileList | null,
    existFile?: Omit<FilesById, 'file'>) => {
    if (file) {
      if (!senderNameStore.isAllowMimeType(file[0])) {
        toastErrorNotification(`Неверный формат файла ${file[0].name}`);
        return;
      }
      // если файл уже был загружен
      if (existFile?.id) {
        await axiosBaseRequestAdTech({
          url: `/api/senderNames/${senderNameId}/files/${existFile?.id}`,
          method: 'DELETE',
        });
        const formData = new FormData();
        formData.append('file', file[0]);
        formData.append('customFileName', file[0].name);
        formData.append('documentTypeId', `${existFile.documentTypeId}`);
        formData.append('documentTypeName', existFile.documentTypeName);
        await axiosBaseRequestAdTech({
          url: `/api/senderNames/${senderNameId}/files`,
          method: 'POST',
          data: formData,
        });
        if (senderNameId) {
          await senderNameStore.getAllFilesBySenderId();
        }
      } else if (senderNameStore.userFilesById) {
        senderNameStore.userFilesById[`${id}`][idx] = {
          documentTypeName: senderNameStore.docsByBusinessType.find(doc => +doc.id === +id)?.documentTypeName || '',
          customFileName: file[0].name,
          file: file[0],
          documentTypeId: +id,
        };
      }
    }
  };

  const onDeleteFile = async (idx: FilesById, id: number | string) => {
    if (idx.file && senderNameStore.userFilesById) {
      senderNameStore.userFilesById[`${id}`].remove(idx);
      if (!senderNameStore.userFilesById[`${id}`]?.length) {
        delete senderNameStore.userFilesById[`${id}`];
      }
    } else if (!idx.file && idx.id) {
      await axiosBaseRequestAdTech({
        url: `/api/senderNames/${senderNameId}/files/${idx.id}`,
        method: 'DELETE',
      });
      if (senderNameId) {
        await senderNameStore.getAllSenderNames();
      }
    }
  };

  const onFileEdit = async (fileId: string, data: { customFileName: string, documentTypeName: string, documentTypeId: string }) => {
    await axiosBaseRequestAdTech({
      url: `/api/senderNames/${senderNameId}/files/${fileId}`,
      method: 'POST',
      data,
    });
    if (senderNameId) {
      await senderNameStore.getAllSenderNames();
    }
  };

  const checkIfSenderNameExist = async (value: string, ban?: number):Promise<number> => {
    return axiosBaseRequestAdTech({
      url: '/api/senderNames/check',
      params: {
        ban,
        senderName: value,
      },
    });
  };

  useEffect(() => {
    if (senderNameId) senderNameStore.getAllComments(+senderNameId);
  }, [senderNameId]);

  useEffect(() => {
    return senderNameStore.resetForm();
  }, []);

  useEffect(() => {
    if (senderNameStore?.form?.senderName) {
      validation.getvalidObj('senderName');
    }
  }, [senderNameStore?.form?.senderName]);

  if (senderNameStore.documentsListViewId) {
    return (
      <SenderNamesFileList
        onReplaceFile={onReplaceFile}
        onDeleteFile={onDeleteFile}
        title={senderNameStore.userFilesById?.[senderNameStore.documentsListViewId]?.[0]?.documentTypeName || ''}
        onFileEdit={onFileEdit}
    />
    );
  }

  if (senderNameStore.showMoreInfo) {
    return <MoreInfoScreen />;
  }

  return (
    <SenderNameContext.Provider
      value={{
        validation,
        senderNameId,
        isIp,
        setIsIp,
        onAddFiles,
        chooseDocsIsVisible,
        isInfoVisited,
        setIsInfoVisited,
        setChooseDocsVisible,
        onFileEdit,
        onReplaceFile,
        onDeleteFile,
        checkIfSenderNameExist,
      }}
    >
      {children}
    </SenderNameContext.Provider>
  );
});

const CreateSenderNameBody = observer(() => {
  const senderContext = useContext<SenderNameContextType | null>(SenderNameContext);
  useEffect(() => {
    if ((sideSheetStore?.sideSheetComponentProps as { id?: string })?.id) {
      (async () => {
        await senderNameStore.getAllFilesBySenderIdSection();
        senderNameStore.form.senderName = NewOrder.smsCampaign.senderName;
        senderNameStore.userFilesById = groupFilesById(senderNameStore.files);
      })();
    }
  }, [sideSheetStore?.sideSheetComponentProps]);
  if (!senderContext) {
    throw new Error('senderContext не найден');
  }
  const {
    validation,
    senderNameId,
    isIp = false,
    setIsIp,
    onAddFiles,
    chooseDocsIsVisible,
    isInfoVisited,
    setIsInfoVisited,
    setChooseDocsVisible,
    checkIfSenderNameExist,
  } = senderContext;
  return (
    <>
      <form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          if (validation.getAllValid().firstErrorKey) {
            return;
          }
          try {
            senderNameStore.loading = true;
            let nameId = '';
            if (!senderNameId) {
              // если это не редактирование
              try {
                const numberSender = await checkIfSenderNameExist(senderNameStore.form.senderName as string, UserInfo?.data?.company?.ban && +UserInfo?.data?.company?.ban || undefined);
                if (numberSender) {
                  validation.setErrorsOfKey('senderName', 'Такое имя уже существует');
                  return;
                }
              } catch (error) {
                if (error?.response?.status === 404) {
                  nameId = await senderNameStore.createName(senderNameStore?.form?.senderName as string);
                }
              }
            } else {
              nameId = senderNameId;
            }
            if (nameId) {
              await senderNameStore.sendAllFiles(nameId);
            }
            if (senderNameStore?.form?.comment) {
              await senderNameStore.sendComment(nameId, senderNameStore?.form?.comment as string);
            }
            NewOrder.smsCampaign.senderName = senderNameStore?.form?.senderName as string;
            NewOrder.smsCampaign.senderId = +nameId;
            await senderNameStore.verification();
            await NewOrder.saveOrder();
            await senderNameStore.getAllSenderNames();
            senderNameStoreOld.files = Object.values(senderNameStore.userFilesById || {}).map(([value]) => value);
            await NewOrder.calculate();
            sideSheetStore.componentToShow = '';
          } catch (error) {
            console.error(error);
            toastErrorNotification('Во время выполнения запроса произошла ошибка');
          } finally {
            senderNameStore.loading = false;
          }
        }}
      >
        <div className={styles.holder}>
          {
            senderNameStore.comments.length &&
            <div className={styles.redHolder}>
              <InlineAlert type="error">
                {senderNameStore.comments[senderNameStore.comments.length - 1].text}
              </InlineAlert>
            </div> || null
          }
          <div className={cx(styles.mainTitle, styles.mb29)}>
            Придумайте имя
            {
              !validation.errors?.senderName &&
              (senderNameStore.form?.senderName as string)?.length &&
              <span>
                <Icon iconName={Icons.CheckCircled} color="green" />
              </span> ||
              null
            }
          </div>
          <SwitchField
            value={isIp}
            onChange={setIsIp}
          />
          <SenderNameText
            error={!!validation.errors?.senderName}
            currentLength={(senderNameStore?.form?.senderName as string)?.length || 0}
            onChange={(e) => {
              senderNameStore.form.senderName = e.currentTarget.value;
            }}
            value={senderNameStore?.form?.senderName as string || ''}
            helperText={validation.errors?.senderName || 'Только латинские буквы, цифры и символы «-», «_», «.».'}
          />
          {
            isIp &&
            <div className={styles.attention}>
              <Icon type="warning" iconName={Icons.WarningTriangle} contained/>
              <TypographyWithDangling variant="body3">
                Имя отправителя должно отличаться от наименования организации
              </TypographyWithDangling>
            </div>
          }

          {(senderNameStore.form?.senderName as string)?.length &&
            <>
              <div className={cx(styles.mainTitle, styles.mb16)}>
                Загрузите любой документ из списка
                {
                  Object.entries(senderNameStore.userFilesById || {}).length &&
                  <span>
                    <Icon iconName={Icons.CheckCircled} color="green" />
                  </span> || null
                }
              </div>
              <div className={styles.attention}>
                <Icon iconName={Icons.WarningCircled} contained/>
                <TypographyWithDangling variant="body3" className={styles.naming}>
                  Называйте каждый файл понятно: например, стр. 1, стр 2 и т.д, если в документе несколько файлов
                </TypographyWithDangling>
              </div>
            </> || null
          }

          {
            Object.entries(senderNameStore.userFilesById || {}).length && Object.entries(senderNameStore.userFilesById || {}).map(([key, value]) => {
              return (
                <UploadedFiles
                  key={value.length}
                  title={value[0]?.documentTypeName}
                  filesLength={value.length}
                  onAddFiles={(e, id) => {
                    if (e.target.files) {
                      onAddFiles(e.target.files, id);
                    }
                  }}
                  onShowList={() => {
                    senderNameStore.documentsListViewId = +key;
                  }}
                  documentTypeId={+key}
                />
              );
            }) || null
          }

          {
            (senderNameStore.form?.senderName as string)?.length &&
            (!validation.errors?.senderName) &&
            chooseDocsIsVisible &&
            <SelectField
              type={isIp ? 1 : 0}
              fetchDocsList={senderNameStore.fetchDocsByBusinessType}
              docsByBusinessType={senderNameStore.docsByBusinessType}
              currentValues={senderNameStore?.form?.documentType as DocsByBusinessList[] || []}
              onChange={(e) => {
                senderNameStore.form.documentType = e;
              }}
              error={!senderNameStore?.form?.documentType ? validation?.errors?.files : ''}
            /> || null
          }

          {
            (senderNameStore?.form?.documentType as { value: string }[])?.[0]?.value === 'Другой документ, разрешающий использовать имя' &&
            chooseDocsIsVisible &&
            <div
              className={cx(styles.pseudoLink, styles.mb16, isInfoVisited && styles.visited)}
              onClick={() => {
                senderNameStore.showMoreInfo = true;
                setIsInfoVisited(true);
              }}>
              <Icon contained={false} iconName={Icons.InfoCircled} color={isInfoVisited ? 'purple' : 'blue'} />
              Как быстро согласовать имя и какие документы не подойдут?
            </div> || null
          }

          {
            (senderNameStore?.form?.documentType as DocsByBusinessList[]) &&
            !validation.errors?.senderName &&
            <FileUploader
              title="Перетащите сюда файлы или"
              linkName="загрузите документы"
              subTitle="pdf, jpg, png, gif, rar, zip до 20 Мб"
              multiple={false}
              error={!!validation?.errors?.files}
              helperText={validation?.errors?.files || ''}
              onChange={(e) => {
                if (e.target.files) {
                  onAddFiles(e.target.files);
                }
              }}
              onDrop={(e) => e?.dataTransfer?.files && onAddFiles(e.dataTransfer.files as FileList)}
            /> || null
          }

          {
            Object.entries(senderNameStore.userFilesById || {}).length &&
            !senderNameStore?.form?.documentType &&
            <Button
              startIcon={<Icon iconName={Icons.Add} />}
              variant="overlay"
              onClick={() => setChooseDocsVisible(true)}
              className={styles.addMore}
              type="button"
            >
              Добавить документ
            </Button> || null
          }

          {
            Object.entries(senderNameStore.userFilesById || {}).length &&
            <>
              <div className={cx(styles.mainTitle, styles.mb29, styles.commentHolder)}>
                Комментарий
              </div>
              <TextArea
                className={styles.comment}
                onChange={(e) => {
                  (senderNameStore.form as { comment: string }).comment = e.currentTarget.value;
                }}
              />
            </> || null
          }
        </div>

        <div className={styles.btnsHolder}>
          <Button
            variant="contained"
            disabled={senderNameStore.loading}
            type="submit"
            className={styles.sendBtn}
          >
            Отправить
          </Button>
        </div>
      </form>
    </>
  );
});


export const SenderNameSideSheet = observer(({ children }: { children: React.ReactNode }) => {
  if (sideSheetStore.componentToShow !== 'senderName') return <>{children}</>;
  const onBack = window.location.pathname.includes('/sender-name') ?
    () => {
      sideSheetStore.componentToShow = '';
      senderNameStore.showMoreInfo = false;
    } : () => {
      senderNameStore.documentsListViewId = null;
      senderNameStore.showMoreInfo = false;
    };
  return (
    <>
      {
        createPortal(<SideSheet
          content={
            <CreateSenderName
              senderNameId={(sideSheetStore?.sideSheetComponentProps as { id:string })?.id}
            >
              <CreateSenderNameBody />
            </CreateSenderName>}
          showBack={!!(senderNameStore.documentsListViewId || senderNameStore.showMoreInfo)}
          title={(senderNameStore.documentsListViewId || senderNameStore.showMoreInfo) ? <>К созданию имени</> : <>Создание имени<br/> отправителя</>}
          onClose={() => {
            sideSheetStore.componentToShow = '';
          }}
          onBack={onBack}
          componentToShow="senderName"
        >
          {children}
        </SideSheet>, document.getElementById('root') || document.body)
      }
    </>
  );
});
