import React, { Suspense, lazy } from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { observer } from 'mobx-react';
import PageLayout from 'redesignSrc/components/PageLayout';
import CreateSenderNameCard from 'redesignSrc/pages/SenderNameCard/CreateSenderName';
import { EditSenderName } from 'redesignSrc/pages/SenderNameCard/EditSenderName';
import { PageLoader } from '../components/common/Loaders';
import {
  MESSAGE_TEMPLATES_URL,
  MY_CAMPAIGNS_STATISTIC_URL,
  MY_CAMPAIGNS_URL, NEW_CAMPAIGN_URL, NEW_SENDER, NEW_TEMPLATE, SENDER_NAMES_URL, USER_INFO_URL,
} from './constants';

const UserInfoPageAggregator = lazy(() => import('redesignSrc/pages/UserInfoPage/UserInfoPageAggregator'));
const MyCampaignsTsms = lazy(() => import('redesignSrc/pagesTsms/MyCampaignsTsms'));
const MessageTemplatesPageTsms = lazy(() => import('redesignSrc/pagesTsms/MessageTemplatesPageTsms'));
const MyAudienceTsms = lazy(() => import('redesignSrc/pagesTsms/MyAudienceTsms'));
const NewTemplate = lazy(() => import('redesignSrc/pages/NewTemplate'));
const NewAudience = lazy(() => import ('redesignSrc/pages/NewAudience'));
const SenderNamesPageTsms = lazy(() => import ('redesignSrc/pagesTsms/SenderNamesPageTsms'));
const MyCampaignsStatisticPage = lazy(() => import ('pages/MyCampaignsStatistic'));
const CreateCampaign = lazy(() => import ('redesignSrc/pages/CreateCampaign'));
const BanAggregator = lazy(() => import ('redesignSrc/pages/UserInfoPage/UserInfoPageAggregator/BanAggregator'));


const RouterPage = (
  props: {
    pageComponent: JSX.Element,
    exact?: boolean,
    prevPathname?: string,
  } & RouteComponentProps,
) => props.pageComponent;


const RoutesWithAggregatorAuth = () => {
  return (
    <>
      <Suspense fallback={<PageLoader isLoading/>}>
        <PageLayout>
          <Router basepath="/" primary={false}>
            <Redirect path="/" from="/" to={MY_CAMPAIGNS_URL} noThrow/>
            <RouterPage pageComponent={<MyCampaignsTsms/>} path="/my-campaigns" default/>
            <RouterPage pageComponent={<MyCampaignsStatisticPage isTsms/>} path={MY_CAMPAIGNS_STATISTIC_URL}/>
            <RouterPage pageComponent={<MyAudienceTsms/>} path="/my-audiences"/>
            <RouterPage pageComponent={<NewAudience isMyAudience/>} path="/new-audience/*"/>
            <RouterPage pageComponent={<MessageTemplatesPageTsms/>} path={MESSAGE_TEMPLATES_URL}/>
            <RouterPage pageComponent={<NewTemplate isTemplateMessage/>} path={`${NEW_TEMPLATE}*`}/>
            <RouterPage pageComponent={<SenderNamesPageTsms/>} path={SENDER_NAMES_URL}/>
            <RouterPage pageComponent={<CreateSenderNameCard />} path={NEW_SENDER}/>
            <RouterPage pageComponent={<EditSenderName isTemplateMessage />} path={`${NEW_SENDER}:id`}/>
            <RouterPage pageComponent={<UserInfoPageAggregator/>} path={`${USER_INFO_URL}*`}/>
            <RouterPage pageComponent={<CreateCampaign isTSMS/>} path={`${NEW_CAMPAIGN_URL}*`}/>
            <RouterPage pageComponent={<BanAggregator/>} path="/ban/:id"/>
          </Router>
        </PageLayout>
      </Suspense>
    </>
  );
};

export default observer(RoutesWithAggregatorAuth);
