import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col } from 'antd';
import { Button } from '@beeline/design-system-react';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import classNames from 'classnames/bind';
import { TextInput, InputRadio } from 'redesignSrc/UI/form';
import { Body3, FormFieldLabel, SmallText } from 'redesignSrc/UI/typography';
import TooltipInfo from 'redesignSrc/UI/elements/TooltipInfo';
import SuggestCheckbox from 'redesignSrc/UI/elements/SuggestListWithSearch/SuggestCheckbox';
import ContainerNotDrag from 'containers/ContainerDnD/ContainerNotDrag';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import NewOrder from 'store/mobx/NewOrder';
import InfoDescription from 'redesignSrc/components/InfoDescription';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { TSMSUserInfo } from 'store/mobx/TSMS/UserInfo';
import danglingPrepositions from 'utils/danglingPrepositions';
import StoreT from '../../storeT';
import { linkify } from '../../utils/utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LinkBlock = () => {
  const isTemplate = window.location.href.includes('new-template');
  const dynamicLinkTemplateCampaign = NewOrder.smsCampaign.links.dynamicPartLink && NewOrder.smsCampaign.templateId;
  const [form] = useForm();
  const [timeLink, setTimeLink] = useState('');
  const [timeDop, setTimeDop] = useState('');

  const removeLastWord = (url: string | null) => {
    if (!url) return '';
    return url.replace(/\/[^/]+$/, '/');
  };

  return (
    <GreyContainerPopup
      title="Вставьте ссылку и мы добавим ее в SMS"
      titleClassName={styles.title}
      onClose={() => {
        StoreT.isChangeCheckbox = false;
        StoreT.isOpenPopupLink = false;
        if (StoreT.linkValue.dynamicPartLink && StoreT.isOpenDynamicLink || NewOrder.order?.smsCampaign?.links.dynamicPartLink) {
          const url = StoreT.timeLink;
          const word = StoreT.timeDop;
          const pattern = new RegExp(`/${word}(?!.*${word})`);
          const hasWord = pattern.test(url || '');

          if (!hasWord) {
            StoreT.timeLink = (StoreT.timeLink || '');
          } else {
            StoreT.timeLink = (removeLastWord(StoreT.timeLink || '')) + (StoreT.timeDop || '');
          }
        } else {
          StoreT.linkValue.dynamicPartLink = '';
          StoreT.timeDop = '';
        }
      }}
    >
      {
        (isTemplate || dynamicLinkTemplateCampaign) && <DynamicLink
          timeLink={timeLink}
          setTimeLink={setTimeLink}
          timeDop={timeDop}
          setTimeDop={setTimeDop}
          isTemplate={isTemplate}
          form={form}
        /> || <RegularLink/>
      }
    </GreyContainerPopup>
  );
};
interface Props {
  isTemplate: boolean;
  form: FormInstance<any>;
  timeLink: string | '';
  setTimeLink: (val: string) => void;
  timeDop: string | '';
   setTimeDop: (val: string) => void;
}


const DynamicLink:FC<Props> = observer(({ isTemplate, form, timeDop }) => {
  const [isShort, setIsShort] = useState(StoreT.linkValue.isShort || false);
  const [isDynamicActive, setIsDynamicActive] = useState(false);
  const [rememberAudience, setRememberAudience] = useState(StoreT.rememberUsers);
  const dynamicLinkTemplateCampaign = NewOrder.smsCampaign.links.dynamicPartLink && NewOrder.smsCampaign.templateId;
  const isAccepted = messageTemplatesStore.status === 'Accepted';

  useEffect(() => {
    setRememberAudience(StoreT.rememberUsers);
  }, [StoreT.rememberUsers]);

  const isEdit = !!StoreT.linkValue.link;

  useEffect(() => {
    if (StoreT.linkValue.link) {
      StoreT.timeLink = StoreT.linkValue.link;
    }
  }, []);

  useEffect(() => {
    if (StoreT.linkValue.dynamicPartLink || StoreT.timeDop) {
      setIsDynamicActive(true);
      StoreT.previewLink = (StoreT?.timeLink || '') + (StoreT?.timeDop || '');
    } else {
      setIsDynamicActive(false);
    }
  }, []);

  const removeLastWord = (url: string | null) => {
    if (!url) return '';
    return url.replace(/\/[^/]+$/, '/');
  };

  useEffect(() => {
    if (StoreT.isOpenPopupLink) {
      if (StoreT.isOpenDynamicLink && StoreT.timeDop) {
        StoreT.timeLink = removeLastWord(StoreT.timeLink) || '';
        form.setFieldsValue({
          link: StoreT.timeLink,
        });
      } else {
        // form.setFieldsValue({
        //   link: removeLastWord(StoreT.linkValue.link || ''),
        // });
      }
    }
  }, [StoreT.isOpenPopupLink]);

  useEffect(() => {
    StoreT.rememberUsers = messageTemplatesStore.withUrlParameters || false;
  }, [messageTemplatesStore.withUrlParameters]);

  const handleSubmit = async (e: { [key: string]: string }) => {
    StoreT.isChangeCheckbox = false;
    StoreT.linkValue.link = null;
    StoreT.linkValue.dynamicPartLink = null;
    StoreT.isOpenDynamicLink = false;
    StoreT.linkValue.isShort = isShort;
    StoreT.linkValue.link = e.dynamicPartLink ? e.link + e.dynamicPartLink : e.link;
    StoreT.linkValue.dynamicPartLink = e.dynamicPartLink || '';
    StoreT.isOpenPopupLink = false;
    if (!isTemplate) {
      setTimeout(async () => {
        NewOrder.smsCampaign.links.dynamicPartLink = e.dynamicPartLink;
        NewOrder.saveOrder();
      }, 1000);
    }
  };

  useEffect(() => {
    StoreT.previewLink = (StoreT?.timeLink || '') + (StoreT?.timeDop || '');
  }, [StoreT.timeLink, StoreT.timeDop]);

  return (
    <>
      <SmallText className={styles.description}>
        Обычная ссылка остается неизменной в шаблоне. Динамическую можно будет менять после сохранения шаблона
      </SmallText>
      <Form
        form={form}
        initialValues={{
          link: NewOrder.smsCampaign.links.link || StoreT.linkValue.link || '',
          dynamicPartLink: (NewOrder.smsCampaign.links?.dynamicPartLink || StoreT.linkValue?.dynamicPartLink) || '' || timeDop,
        }}
        onValuesChange={async (val, allValues) => {
          StoreT.timeLink = allValues.link;
          StoreT.timeDop = allValues.dynamicPartLink;
          StoreT.previewLink = `${allValues?.link || ''}${allValues?.dynamicPartLink || ''}`;
        }}
        onFinish={(e) => {
          handleSubmit(e);
        }}
        key={`${StoreT.isChangeCheckbox}`}
      >
        {() => (
          <>
            <Row>
              <Col span={12}>
                <ContainerNotDrag>
                  <Form.Item name="regularType">
                    <InputRadio
                      onChange={() => {
                        setIsDynamicActive(false);
                        StoreT.timeDop = form.getFieldValue('dynamicPartLink');
                        StoreT.previewLink = (StoreT?.timeLink || '') + (StoreT?.timeDop || '');
                        if (form.isFieldTouched('link')) {
                          form.validateFields();
                        }
                      }}
                      checked={!isDynamicActive}
                      disabled={(!!dynamicLinkTemplateCampaign || isAccepted) && !isTemplate}
                    >
                      Обычная ссылка
                    </InputRadio>
                  </Form.Item>
                </ContainerNotDrag>
              </Col>
              <Col span={12}>
                <ContainerNotDrag>
                  <Form.Item name="dynamicType">
                    <InputRadio
                      onChange={() => {
                        StoreT.timeDop = form.getFieldValue('dynamicPartLink');
                        setIsDynamicActive(true);
                        StoreT.previewLink = (StoreT?.timeLink || '') + (StoreT?.timeDop || '');
                        if (form.isFieldTouched('link')) {
                          form.validateFields();
                        }
                      }}
                      checked={isDynamicActive}
                      disabled={(!!dynamicLinkTemplateCampaign || isAccepted) && !isTemplate}
                    >
                      Динамическая ссылка
                    </InputRadio>
                  </Form.Item>
                </ContainerNotDrag>
              </Col>
            </Row>
            <ContainerNotDrag>
              <div className={styles.labelInfo}>
                <FormFieldLabel>
                  {
                    isDynamicActive &&
                    'Неизменная часть ссылки' ||
                    'Ссылка для перехода'
                  }
                </FormFieldLabel>
                {
                  isDynamicActive &&
                  <TooltipInfo className={styles.tooltip1}>
                    Ссылка на сайт не должна содержать знаки препинания, не являющиеся частью ссылки, и пробелы
                    <br/>
                    <br/>
                    Для отслеживания кликов в Яндекс Метрике и в других системах аналитики, добавьте к ссылке
                    специальную разметку.
                  </TooltipInfo> ||
                  <TooltipInfo className={styles.tooltip1}>
                    Вы можете вставить ссылку на ваш сайт как с UTM меткой, так и без нее
                  </TooltipInfo>
                }
              </div>
              <Form.Item
                name="link"
                rules={
                  [
                    { required: true, message: 'Обязательное поле' },
                    {
                      validator(_, value) {
                        const links = linkify.match(value);
                        if (links && links[0].raw === value) {
                          return Promise.resolve();
                        }
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Проверьте корректность добавленной ссылки, возможно она содержит опечатки');
                      },
                    },
                    {
                      validator(_, value) {
                        if (isDynamicActive && value && value[value.length - 1] !== '/') {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('Эта часть ссылки должна заканчиваться на /');
                        }
                        return Promise.resolve();
                      },
                    },
                    {
                      validator(_, value) {
                        if (
                          isDynamicActive &&
                          value &&
                          (value[value.length - 1] === '/' && value[value.length - 2] === '/')
                        ) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('В конце допускается использовать только один знак /');
                        }
                        return Promise.resolve();
                      },
                    },
                ]
                }
              >
                <TextInput
                  placeholder="Например, https://marketing.beeline.ru"
                  name="link"
                  disabled={(!!dynamicLinkTemplateCampaign || isAccepted) && !isTemplate}
                />
              </Form.Item>
            </ContainerNotDrag>
            {
              isDynamicActive &&
              <>
                <ContainerNotDrag>
                  <div>
                    <FormFieldLabel>Динамическая часть ссылки</FormFieldLabel>
                  </div>
                  <Form.Item
                    name="dynamicPartLink"
                    rules={
                      [
                        { required: true, message: 'Обязательное поле' },
                        {
                          validator(_, value) {
                            if (isDynamicActive && value && value.includes('/')) {
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject('Эта часть ссылки не должна содержать /');
                            }
                            return Promise.resolve();
                          },
                        }, {
                        validator(_, value) {
                          if (isDynamicActive && value && value.includes(' ')) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject('Эта часть ссылки не должна содержать пробелы');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]
                    }

                  >
                    <TextInput
                      placeholder="Например, contacts"
                      name="dynamicPartLink"
                    />
                  </Form.Item>
                </ContainerNotDrag>

                <ContainerNotDrag>
                  <Form.Item>
                    <div className={styles.labelInfo}>
                      <FormFieldLabel>Полная ссылка</FormFieldLabel>
                    </div>

                    <TextInput
                      placeholder="Тут будет отображаться Ваша ссылка"
                      name="previewDynamic"
                      value={StoreT.previewLink || ''}
                      disabled
                    />
                  </Form.Item>
                </ContainerNotDrag>
              </>
            }

            <div className={styles.shortLinkCheck}>
              <SuggestCheckbox
                className={styles.checkBox}
                value={isShort}
                onChange={() => {
                  setIsShort(!isShort);
                  if (!isShort === false) {
                    StoreT.rememberUsers = false;
                  }
                }}
                disabled={(!!dynamicLinkTemplateCampaign || isAccepted) && !isTemplate}
              >
                Укоротить и считать клики
              </SuggestCheckbox>
              {
                isDynamicActive &&
                <TooltipInfo className={styles.tooltip2}>
                  Вы можете использовать ссылку на сайт как со специальной разметкой,
                  так и без нее. Преобразование ссылки для подсчета кликов на нашей
                  стороне не повредит вашей разметке. Мы автоматически заменим вашу ссылку
                  на ссылку для подсчета кликов и дополнительно сократим ее длину.
                  Укороченная нами ссылка не учитывается в расчете длины текста сообщения.
                  Статистику по кликам вы увидите в отчете по завершению рассылки.
                </TooltipInfo> ||
                <TooltipInfo className={styles.tooltip2}>
                  {
                    danglingPrepositions('Мы автоматически заменим вашу ссылку в тексте на ссылку для подсчета кликов. Статистику по кликам вы увидите в отчете по завершению рассылки \n\n' +
                      'Преобразование ссылки для подсчета кликов на нашей стороне не повредит вашей UTM метке\n\n' +
                      'Укороченная ссылка не учитывается в расчете длины текста сообщения')
                  }
                </TooltipInfo>
              }
            </div>
            {isShort &&
              <>
                <div className={styles.labelInfo}>
                  <FormFieldLabel>Как будет отображаться в тексте</FormFieldLabel>
                  {
                    isDynamicActive &&
                    <TooltipInfo className={styles.tooltip1}>
                      Ссылка примет укороченный вид https://beel.ink*****, где ***** индивидуальный набор символов для
                      каждого абонента
                    </TooltipInfo> ||
                    <TooltipInfo className={styles.tooltip1}>
                      Ваша ссылка в тексте будет отображаться в виде https://beel.ink/****
                    </TooltipInfo>
                  }

                </div>
                <TextInput
                  className={styles.shortInput}
                  placeholder="https://beel.ink/xpkj"
                  disabled
                />
              </>
            }
            {
              TSMSUserInfo.data?.customerSettings?.isUUIDVisibility &&
              <div className={cx(styles.shortLinkCheck, styles.mt24)}>
                <SuggestCheckbox
                  className={styles.checkBox}
                  disabled={messageTemplatesStore.status === 'Accepted' || !!NewOrder?.smsCampaign?.templateId}
                  value={StoreT.rememberUsers}
                  onChange={(e) => {
                    StoreT.rememberUsers = e;
                    messageTemplatesStore.withUrlParameters = e;
                    StoreT.isChangeCheckbox = true;
                    if (e && !isShort) {
                      setIsShort(true);
                    }
                  }}
                >
                  Запомнить пользователей для повторной рекламы
                </SuggestCheckbox>
                <TooltipInfo className={styles.tooltip2}>
                  {
                    danglingPrepositions('Инструмент подходит тем, кто рассылает на широкую аудиторию \nот 20 000 абонентов. \n\n' +
                      'Соберем обезличенные данные о пользователях, которые проявили интерес к сообщению и перешли по ссылке')
                  }
                </TooltipInfo>
              </div>
            }
            <InfoDescription className={styles.blueBlock}>
              <Body3>
                Пользователь подтверждает, что содержание текста рекламного сообщения совпадает с контентом по ссылке на
                всё время проведения рекламной кампании
              </Body3>
            </InfoDescription>
            <div className={styles.buttonContainer}>
              <Button
                variant="overlay"
                size="medium"
                type="submit"
                disabled={!form.isFieldsTouched() && !StoreT.isChangeCheckbox}
              >
                {isEdit ? 'Редактировать ссылку' : 'Добавить в текст'}
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
});

const RegularLink = observer(() => {
  const [isShort, setIsShort] = useState(StoreT.linkValue.isShort || false);
  const [form] = useForm();

  const isEdit = !!StoreT.linkValue.link;

  const handleSubmit = (e: { [key: string]: string }) => {
    StoreT.linkValue.isShort = isShort;
    StoreT.linkValue.link = e.link;
    StoreT.isOpenDynamicLink = false;
    StoreT.isOpenPopupLink = false;
    NewOrder.smsCampaign.links.link = e.link;

    NewOrder.smsCampaign.links.isShort = isShort;
    NewOrder.saveOrder();
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{
          link: NewOrder.smsCampaign.links.link || StoreT.linkValue.link,
        }}
        onFinish={(e) => {
          handleSubmit(e);
        }}
        key={`${StoreT.rememberUsers}`}
      >
        {() => (
          <>
            <ContainerNotDrag>
              <div className={styles.labelInfo}>
                <FormFieldLabel>Ссылка для перехода</FormFieldLabel>
                <TooltipInfo className={styles.tooltip1}>
                  Вы можете вставить ссылку на ваш сайт как с UTM меткой, так и без нее
                </TooltipInfo>
              </div>
              <Form.Item
                name="link"
                rules={
                  [
                    { required: true, message: 'Обязательное поле' },
                    {
                      validator(_, value) {
                        const links = linkify.match(value);
                        if (links && links[0].raw === value) {
                          return Promise.resolve();
                        }
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Проверьте корректность добавленной ссылки, возможно она содержит опечатки');
                      },
                    },
                ]
                }
              >
                <TextInput
                  placeholder="Например, https://marketing.beeline.ru"
                  name="link"
                />
              </Form.Item>
            </ContainerNotDrag>

            <div className={styles.shortLinkCheck}>
              <SuggestCheckbox
                className={styles.checkBox}
                value={isShort}
                onChange={() => {
                  setIsShort(!isShort);
                  if (!isShort === false) {
                    StoreT.rememberUsers = false;
                  }
                }}
              >
                Укоротить и считать клики

              </SuggestCheckbox>
              <TooltipInfo className={styles.tooltip2}>
                {
                  danglingPrepositions('Мы автоматически заменим вашу ссылку в тексте на ссылку для подсчета кликов. Статистику по кликам вы увидите в отчете по завершению рассылки \n\n' +
                    'Преобразование ссылки для подсчета кликов на нашей стороне не повредит вашей UTM метке\n\n' +
                    'Укороченная ссылка не учитывается в расчете длины текста сообщения')
                }
              </TooltipInfo>
            </div>

            {isShort &&
              <>
                <div className={styles.labelInfo}>
                  <FormFieldLabel>Как будет отображаться в тексте</FormFieldLabel>
                  <TooltipInfo className={styles.tooltip1}>
                    Ваша ссылка в тексте будет отображаться в виде https://beel.ink/****
                  </TooltipInfo>
                </div>
                <TextInput
                  className={styles.shortInput}
                  placeholder="https://beel.ink/xpkj"
                  disabled
                />
              </>
            }
            {
              TSMSUserInfo.data?.customerSettings?.isUUIDVisibility &&
              <div className={cx(styles.shortLinkCheck, styles.mt24)}>
                <SuggestCheckbox
                  className={styles.checkBox}
                  disabled={messageTemplatesStore.status === 'Accepted' || !!NewOrder?.smsCampaign?.templateId}
                  value={StoreT.rememberUsers}
                  onChange={(e) => {
                    StoreT.rememberUsers = e;
                    messageTemplatesStore.withUrlParameters = e;
                    StoreT.isChangeCheckbox = true;
                    if (e && !isShort) {
                      setIsShort(true);
                    }
                  }}
                >
                  Запомнить пользователей для повторной рекламы
                </SuggestCheckbox>
                <TooltipInfo className={styles.tooltip2}>
                  {
                    danglingPrepositions('Инструмент подходит тем, кто рассылает на широкую аудиторию \nот  20 000 абонентов. \n\n' +
                    'Соберем обезличенные данные о пользователях, которые проявили интерес к сообщению и перешли по ссылке')
                  }
                </TooltipInfo>
              </div>
            }
            <InfoDescription className={styles.blueBlock}>
              <Body3>
                Пользователь подтверждает, что содержание текста рекламного сообщения совпадает с контентом по ссылке на
                всё время проведения рекламной кампании
              </Body3>
            </InfoDescription>
            <div className={styles.buttonContainer}>
              <Button
                variant="overlay"
                size="medium"
                type="submit"
              >
                {isEdit ? 'Редактировать ссылку' : 'Добавить в текст'}
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
});

export default observer(LinkBlock);
