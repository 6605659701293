import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { Divider } from '@beeline/design-system-react';
import { formatPriceTotal } from 'utils/formatting';
import newAudiences from 'store/mobx/NewAudiences';
import NewOrder from 'store/mobx/NewOrder';
import { customerDiscounts } from 'store/mobx/CustomerDiscounts/CustomerDiscounts';
import { WarningAudienceIcon } from '../../elements/WarningAudiensWidgets';
import styles from './style.pcss';

type Props = {
  className?: string
  totalText?: any,
  showBudgetPreview?: boolean
}

const TotalPrice: React.FC<Props> = ({ className, totalText, showBudgetPreview }) => {
  useEffect(() => {
    NewOrder.calculate();
  }, []);
  const isSale = NewOrder.calculateData?.discount !== 0 && typeof NewOrder.calculateData?.discount === 'number';
  const isBonusesSale = NewOrder.calculateData?.discount === 0 && customerDiscounts.planBonusValue || !!NewOrder.calculateData?.totalCostAdjustment || NewOrder.calculateData?.costAdjustments?.length === 2;

  const renderPrice = () => {
    if (!newAudiences.isCustom) {
      return formatPriceTotal(NewOrder.calculateData?.total || 0);
    }

    if (newAudiences.isCustom) {
      if (customerDiscounts?.planBonusValue) {
        return NewOrder.calculateData?.total && customerDiscounts?.planBonusValue && formatPriceTotal(+NewOrder.calculateData?.total - Number(customerDiscounts?.planBonusValue));
      }
      return NewOrder.calculateData?.total;
    }
  };

  const renderTotalCrossedOut = () => {
    if (!newAudiences.isCustom) {
      return ((isSale && !newAudiences.isCustom) || isBonusesSale && customerDiscounts.isBonusesUsed) && formatPriceTotal(NewOrder.calculateData?.price || 0);
    }
    return NewOrder.calculateData?.price;
  };
  return (
    <>
      {
        showBudgetPreview &&
        <div className={styles.budgetInfoHolder}>
          <div className={styles.budgetInfo}>
            <div>Параметры</div>
            <div>Цена</div>
          </div>
          <div className={styles.budgetPrice}>
            <div>Бюджет</div>
            <div>{formatPriceTotal(NewOrder.calculateData?.price || 0)}</div>
          </div>
        </div>
      }
      <Divider className={styles.divider}/>

      <div className={classNames(styles.container, className)}>
        <div className={styles.wraperTextTotal}>
          <span className={styles.title}>Итого</span>
          {
            !newAudiences.isCustom ?
              (<span
                className={classNames(styles.sale, ((isSale && !newAudiences.isCustom) || (isSale && !newAudiences.isCustom) || isBonusesSale && customerDiscounts.isBonusesUsed) && styles.activeSale)}>
                {renderTotalCrossedOut()}
              </span>) :
              (<span/>)
          }
          <span className={styles.budget}>
            {renderPrice()}
          </span>
        </div>


        <WarningAudienceIcon
          className={styles.warning}
          isTotalBlock
          TotalText={totalText}
        />
      </div>
    </>
  );
};

export default observer(TotalPrice);
