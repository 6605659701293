import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { LegalInfo } from 'redesignSrc/pages/NewTemplate/components/TemplateCustomization/components';
import { valueToText } from 'redesignSrc/pagesTsms/components/FilterPanel/utils';
import UploadingScale from '../../../../../../../components/UploadingScale';
import { axiosBaseRequestAdTech } from '../../../../../../../../requests/helpers';
import { ORDER_FILES_FIELD } from '../../../../../../constants';
import { PopupStateless } from '../../../../../../../UI/modals/Popup';
import { PrimaryButton } from '../../../../../../../UI/buttons';
import NewOrder from '../../../../../../../../store/mobx/NewOrder';
import { senderNameStore } from '../../SenderNameStore';
import { SenderNameFiles } from './components/SenderNameFiles/SenderNameFiles';
import { UploadSenderNameFilesProps } from './UploadSenderNameFilesProps';

import FileIcon from './assets/File.svg';
import DownloadFileIcon from './assets/download-icon.svg';
import DeleteFile from './assets/DeleteFile.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const UploadSenderNameFiles: FC<UploadSenderNameFilesProps> = observer((props) => {
  const { store, fileUploadInfoTooltip, fileSize, validation, isEditMode } = props;
  const MAX_LOADED_FILES = 10;
  const [showSendDocs, setShowSendDocs] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<null | number>(null);

  useEffect(() => {
    if (isEditMode && NewOrder.smsCampaign.senderId) {
      store.getExistingFiles();
    }
  }, []);


  const handleDeleteFile = (fileId: number) => () => {
    setShowSendDocs(true);
    setSelectedFileId(fileId);
  };

  const handleDownloadFile = (path: string, file: any) => async (e: any) => {
    e.preventDefault();

    const fileView = await axiosBaseRequestAdTech({
      url: path,
      responseType: 'blob',
    });
    const docLink = document.createElement('a');
    docLink.href = window.URL.createObjectURL(fileView);
    docLink.download = file.name || file.filename;
    docLink.click();
  };

  const popup = () => {
    const onCancel = () => {
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    const onOk = () => {
      selectedFileId && store.handleFileRemove(selectedFileId, isEditMode);
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    return (
      <PopupStateless
        opened
        className={styles.sendModal}
        onClose={onCancel}>
        <div className={styles.sendModalTitle}>Удаление файла</div>
        <div>Вы уверены, что хотите удалить файл?</div>
        <PrimaryButton
          className={styles.sendModalBtn}
          onClick={onOk}
        >
          Да
        </PrimaryButton>
        <PrimaryButton
          className={styles.sendModalBtnCancel}
          onClick={onCancel}
        >
          Отмена
        </PrimaryButton>
      </PopupStateless>
    );
  };
  const isDisabledFields = () => store?.files?.length >= MAX_LOADED_FILES || store?.clientFiles.length >= MAX_LOADED_FILES || ((store?.files?.length + store?.clientFiles.length) >= MAX_LOADED_FILES);
  return (
    <div>

      { store?.files && store?.files?.length && <p className={styles.label}>Документы, подтверждающие право использования</p> || null}

      {
       store?.files && store?.files?.length && store?.files?.map((file: any) => {
          const name = file?.customFileName || file.name || file?.filename;
          const path = `/api/senderNames/${NewOrder.smsCampaign.senderId}/files/${file?.id}`;
          return (
            <div className={styles.uploadedFile} key={file.id}>
              <div>
                <FileIcon className={styles.iconDoc} />
              </div>
              <div className={styles.column}>
                <div className={styles.docName} title={name}>{valueToText(name, 50)}</div>
              </div>
              <div className={styles.closeHolder}>
                <div className={styles.row}>
                  <DownloadFileIcon className={styles.icon} onClick={handleDownloadFile(path, file)} />
                  {
                    senderNameStore.isShowUploadFiles() && (
                    <button className={styles.button} onClick={handleDeleteFile(file.id)}>
                      <DeleteFile className={styles.deleteIcon} />
                    </button>
                    )
                  }
                </div>
              </div>
            </div>
          );
        }) || null
      }
    </div>
  );
});
