import { action, observable, runInAction, computed, toJS } from 'mobx';
import { CLIENT_INDUSTRY_API_URL } from 'requests/constants';
import { CLIENT_ORDERS_STATUSES_API_URL } from 'requests/orders/constants';
import { pollsStatusesTranslateMap } from 'store/common/ordersStatuses/constants';
import { axiosAuthorizedRequest, axiosBaseRequestAdTech } from 'requests/helpers';
import { requestChannelTypes } from 'requests/campaigns';
import {
  CHANNEL_STUB_INTERNET,
  CHANNEL_STUB_PUSH,
  CHANNEL_STUB_SMS,
  CHANNEL_STUB_TARGET_INTERNET,
  CHANNEL_STUB_VOICE,
  CHANNEL_DIGITAL_BILLBOARDS_ON_CITY_STREETS,
} from 'constants/index';
import { newYearBudget } from '../../redesignSrc/pages/VoicePage/VoicePageSetup';
import { formatPriceWithLabel } from '../../utils/formatting';
import UserInfo from './UserInfo';

const CONSTANTS_API_URL = '/constants/';

let uniqKey = 1;

const hardcodedChannels = [
  {
    id: 2,
    name: 'SMS-рассылка',
    type: 'Sms',
    channel_uid: 'target-sms',
    description: '<ul><li>Рассылка по абонентам билайн — <b>без минимального бюджета</b></li><li>Рассылка по 3 операторам (билайн, МегаФон, МТС) — от <b>100 000 ₽</b></li></ul>',
    linkWiki: 'Возможности SMS-рассылок >',
    lal: true,
    default_initiate: true,
    is_self_employed: true,
    is_my_audience: true,
    isTsms: true,
  },
  {
    id: 3,
    type: 'VoiceTarget',
    name: 'Голосовой обзвон',
    channel_uid: 'voice-target',
    description: '<p>Самостоятельный обзвон — <br/>от <b>12 000 ₽</b></p></p>',
    linkWiki: null,
    lal: false,
    default_initiate: true,
    is_self_employed: true,
    is_my_audience: false,
    isTsms: false,
  },
  {
    id: 1,
    name: 'Соцсети и сайты',
    channel_uid: 'internet',
    type: 'target-internet-otm',
    description: '<p>Посты и репосты в соцсетях — <br/>от <b>10 000 ₽</b></p><p>Контекстная реклама (поиск Яндекс.Директ) — от <b>30 000 ₽</b></p>',
    linkWiki: 'Возможности интернет-рекламы >',
    offset_date_start: 5,
    default_initiate: true,
    is_self_employed: false,
    is_my_audience: false,
    lal: false,
    isTsms: false,
    onClick: () => {
      window.ym(87718585, 'reachGoal', 'button_internet');
    },
  },
  {
    id: 111,
    name: 'Наружная реклама',
    channel_uid: 'digital-billboards',
    type: 'DigitalBillboard',
    description: '<p>Реклама на цифровых экранах <br/>в посещаемых точках городов – от <b>15 000 ₽</b></p>',
    linkWiki: 'О наружной рекламе >',
    lal: false,
    default_initiate: true,
    is_self_employed: false,
    is_my_audience: false,
    isTsms: false,
    onClick: () => {
      window.ym(87718585, 'reachGoal', 'button_outdoor ');
    },
  },
  {
    id: 7,
    type: 'Push',
    name: 'PUSH Stories',
    channel_uid: 'push',
    description: '<p>Всплывающее рекламное сообщение на экране смартфона, состоящее <br/>из нескольких экранов — <br/><b>без минимального бюджета</b></p>',
    linkWiki: 'Возможности PUSH Stories >',
    lal: true,
    default_initiate: true,
    is_self_employed: true,
    is_my_audience: false,
    isTsms: false,
  },
  {
    id: 112,
    type: 'AudioAds',
    name: 'Аудиореклама',
    channel_uid: 'audio-ads',
    description: '<p>Реклама в магазинах сетей Магнит и Дикси — от <b>10 000 ₽</b></p>',
    linkWiki: 'Об аудиорекламе >',
    default_initiate: false,
    lal: false,
    is_self_employed: true,
    is_my_audience: false,
    isTsms: false,
    onClick: () => {
      window.ym(87718585, 'reachGoal', 'button_audio');
    },
  },
];


export const OFFLINE_CHANNELS = [
  'Sms',
  'Push',
  'VoiceTarget',
];

export const REAL_TIME_CHANNELS = [
  'Sms',
  'Push',
];

export const MULTI_OPERATORS_CHANNELS = [
  'Sms',
];

export const CHANNELS_TYPE_AUDIENCE = {
  Offline: OFFLINE_CHANNELS, // 0
  Custom: REAL_TIME_CHANNELS, // 1
  RealTime: REAL_TIME_CHANNELS, // 1
  MultiOperators: MULTI_OPERATORS_CHANNELS, // 3
};


const DEFAULT_OPTION = { value: null, label: 'Все каналы' };
const availableChannels = [CHANNEL_STUB_SMS, CHANNEL_STUB_INTERNET, CHANNEL_DIGITAL_BILLBOARDS_ON_CITY_STREETS, CHANNEL_STUB_TARGET_INTERNET, CHANNEL_STUB_VOICE, CHANNEL_STUB_PUSH];

const defaultAvailablePromocodes = {
  [CHANNEL_STUB_SMS]: null,
  [CHANNEL_STUB_INTERNET]: null,
  [CHANNEL_DIGITAL_BILLBOARDS_ON_CITY_STREETS]: null,
  [CHANNEL_STUB_TARGET_INTERNET]: null,
  [CHANNEL_STUB_VOICE]: null,
  [CHANNEL_STUB_PUSH]: null,
};

const defaultConstants = {
  CALL_START_DATE_FOR_CLIENTS: 0,
  CALL_START_DATE_FOR_NOT_CLIENTS: 0,
  SMS_START_DATE: 1,
  EXTERNAL_OPERATOR_MIN_BUDGET: 40000,
  EXTERNAL_OPERATOR_NAME_SENDER_BUDGET: 3000,
  MAX_SMS_BUDGET: 1000000,
  BANNER_START_DATE: 10,
  MIN_BEECTN_COUNT: 300,
  FOCUS_DRAFT_COUNT: 3,
  NOTIFICATION_SHOW_TIME: 4,
  MIN_TOOLS_BUDGET: 10000,
  MAX_TOOLS_BUDGET: 100000,
  MY_TARGET_MIN_AUDIENCE: 80,
  VOICE_CPA_CONNECTION_TAB_1_BUDGET: 60000,
  VOICE_CPA_CONNECTION_TAB_2_BUDGET: 120000,
  VOICE_CPA_CONNECTION_TAB_3_BUDGET: 120000,
  START_TIME: '10:00',
  FREE_FOCUS: true,
  MIN_POLL_BUDGET: 0,
  MIN_BUDGET_VOICE_CUSTOM_SEGMENT: 120000,
  MY_AUDIENCE_NAME_SENDER_COST: 3000,
  PUSH_LOWER_COST: '3.6',
  PUSH_ABOVE_COST: '3.53',
  SMS_SEGMENT_LOWER_COST: '2.40',
  SMS_SEGMENT_ABOVE_COST: '2.35',
  MIN_POLL_RESPONSES: 1,
  POLL_CONVERSION: '100',
  AUDIENCE_MULTIPLY: '3',
  MIN_WIFI_SMS_BUDGET: '17.40',
  GEO_ONLINE_IS_ON: true,
  TARIFF_BY_DELIVERED: true,
};

class Common {
  @observable industries = [];
  @observable.ref internetChannelIndustries = [];
  @observable constants = defaultConstants;
  @observable.ref statusesOrder = [];
  @observable orderTypes = [];
  @observable loadingPromocodes = false;
  @observable.ref statusesCampaign = [];
  @observable channelTypesLoading = false;
  @observable availablePromocodes = defaultAvailablePromocodes;
  @observable.ref docTypes = [];
  @observable isMobileSidebarVisible = false;
  @observable isFloatingPanelVisible = false;
  @observable statusDescription = {};
  @observable typeDescription = {};
  @observable errorInfo = {
    statusCode: null,
    type: null,
    message: null,
  };
  @observable isAskCreateCampaignVisible = false;
  @observable settings = null
  @observable notifications = [];
  @observable.ref channelTypes = [];
  @observable.ref channelsWithBriefs = [];

  @action set = (property, value) => {
    this[property] = value;
  }

  @action pushNotification = (type) => {
    const curNotification = {
      id: uniqKey++,
      notificationIndex: this.notifications.length,
      type,
    };

    this.notifications.push(curNotification);

    // get mobx observable pointer
    const lastNotification = this.notifications[this.notifications.length - 1];

    setTimeout(() => {
      runInAction(() => {
        this.removeNotification(lastNotification);
      });
    }, 7000);
  }

  @action getSettings = async () => {
    this.settings = await axiosBaseRequestAdTech({ url: '/api/settings', method: 'GET' });
  }

  @action removeNotification = notification => {
    this.notifications.remove(notification);
  }

  @action getDocTypes = async () => {
    const response = await axiosAuthorizedRequest({
      url: '/api/v1/client/company_docs/doc_types/',
    });
    runInAction(() => {
      this.docTypes = response;
    });
  }

  @action setError = ({ statusCode, type, message }) => {
    this.errorInfo = {
      statusCode,
      type,
      message,
    };
  };

  @action getAvalablePromocodes = async () => {
    try {
      this.loadingPromocodes = true;
      this.availablePromocodes = defaultAvailablePromocodes;
      const response = await axiosAuthorizedRequest({
        url: '/api/v1/client/campaigns/current_promo_codes/',
      });
      availableChannels.forEach(channel => {
        if (response[channel]) {
          this.availablePromocodes[channel] = response[channel];
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingPromocodes = false;
    }
  }


  getStatusesByTranslateMap(translateMap) {
    const options = [];

    options.push({ value: '', label: 'Не выбрано' });

    const translatedStatuses = this.statusesOrder.reduce((acc, curr) => {
      if (translateMap[curr.id]) {
        acc.push({
          label: translateMap[curr.id] || curr.label,
          value: curr.id,
        });
      }

      return acc;
    }, []);

    options.push(...translatedStatuses);

    return options;
  }

  @computed get getPollsStatusesOptions() {
    return this.getStatusesByTranslateMap(pollsStatusesTranslateMap);
  }

  @computed get docTypesList() {
    return this.docTypes?.length ? this.docTypes.map(item => {
      return { label: item, value: item };
    }) : [{ value: '', label: '' }];
  }

  renameStatus = () => {
    this.statusesOrder = this.statusesOrder?.map((status) => {
      if (status.value === 'Moderation') {
        status.description = 'Проверка перед запуском';
        return status;
      }
      return status;
    });
  }

  getStatuses = async () => {
    if (this.statusesOrder.length !== 0) return;
    const statuses = await axiosBaseRequestAdTech({
      url: '/api/orderStatus',
    });

    runInAction(() => {
      this.statusesOrder = statuses;
      if (!this.statusDescription) {
        this.statusDescription = {};
      }
      this.renameStatus();
      statuses.forEach(status => {
        this.statusDescription[status.value] = status.description;
      });
    });
  };

  getOrders = async () => {
    if (this.orderTypes.length !== 0) return;
    const orders = await axiosBaseRequestAdTech({
      url: '/api/orderType',
    });
    runInAction(() => {
      orders.forEach(order => {
        if (!this.typeDescription) {
          this.typeDescription = {};
        }
        this.typeDescription[order.value] = order.value === 'CustomSegment' ? 'Сбор индивидуальной аудитории' : order.description;
        this.typeDescription[order.value] = order.value === 'Push' ? 'PUSH Stories' : order.description;
      });
      this.orderTypes = orders;
    });
  }

  @action getConstants = async () => {
    // const response = await axiosAuthorizedRequest({ url: CONSTANTS_API_URL });
    // runInAction(() => { this.constants = response; });
  };

  getIndustries = async () => {
    if (this.industries.length) return;
    const response = await axiosBaseRequestAdTech({ url: CLIENT_INDUSTRY_API_URL });
    runInAction(() => {
      this.industries = response.map(item => ({
        value: item.id,
        label: item.name,
      }));
    });
  }

  getInternetChannelIndustries = async () => {
    if (this.internetChannelIndustries.length) return;
    const response = await axiosAuthorizedRequest({ url: '/api/v1/infotech/industries/' });
    runInAction(() => {
      this.internetChannelIndustries = response;
    });
  }

  getCampaignsChannelTypes = async () => {
    if (this.channelTypes.length) return;
    try {
      this.channelTypesLoading = true;
      runInAction(() => {
        this.channelTypes = hardcodedChannels;
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.set('channelTypesLoading', false);
    }
  }

  @computed get getChannelsOptions() {
    return [DEFAULT_OPTION, ...this.channelTypes
      .filter(item => item.channel_uid !== 'focus')
      .map(item => ({ label: item.name, value: item.channel_type_id }))];
  }

  @action getChannelInfoByUid = uid => {
    return this.channelTypes.find(item => {
      return item.channel_uid === uid;
    });
  }

  @action getAllChannels = (isSelf) => {
    return this.channelTypes.filter(item => {
      if (isSelf) {
        return item.default_initiate && item.is_self_employed;
      }
      return item.default_initiate;
    });
  }

  @action getMyAudienceChannels = (type, isSelf) => {
    if (isSelf) {
      return this.channelTypes.filter(item => {
        return CHANNELS_TYPE_AUDIENCE[type].includes(item.type) &&
          item.is_self_employed &&
          item.default_initiate;
      });
    }
    return this.channelTypes.filter(item => {
      return CHANNELS_TYPE_AUDIENCE[type].includes(item.type) && item.default_initiate;
    });
  }

  @action getIsTsmsChannel = () => {
    return this.channelTypes.filter(item => item.isTsms).map(channel => {
      if (channel.type === 'Sms' && UserInfo.isAggregator) {
        return {
          ...channel,
          description: '<ul><li>Рассылка по абонентам билайн — <b>без минимального бюджета</b></li></ul>',
        };
      }
      return channel;
    });
  }

  @action getIsLalChannel = () => {
    return this.channelTypes.filter(item => item.lal);
  }
}

export default new Common();
