import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { Card, Divider, IconButton, PopoverHelper } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { formatPriceTotal } from 'utils/formatting';
import NewOrder, { PRICE_FOR_BANNER_TEXT } from 'store/mobx/NewOrder';
import NewAudiences from 'store/mobx/NewAudiences';
import styles from './style.pcss';
import useUtilsCount from './useUtilsCount';
import TextAudience from './TextAudience';
import SmsWidgetPrice from './SmsWidgetPrice';


interface IProps {
  className?: string
  isTSMS?: boolean
  isMyAudience?: boolean
  isCampaign?: boolean,
  titleMain?: string,
}

const WidgetAudience: FC<IProps> = observer(({
  className,
  isTSMS,
  isMyAudience,
  isCampaign,
  titleMain,
}) => {
  const [togglePrice, setToggleprice] = useState<boolean>(false);
  const isVoiceTarget = NewOrder.order?.type === 'VoiceTarget';
  const isShowLineAndToggle = !!NewOrder.calculateData?.total;
  const isTemplate = window.location.href.includes('new-template');
  const isBillboards = window.location.href.includes('DigitalBillboard');
  const isAudioAds = window.location.href.includes('AudioAds');

  const {
    renderBaseSmsPriceForOtherOper,
    renderBase,
    priceBase,
    showTotalPrice,
    showDefaultPrice,
    showTitlePrice,
    renderTotalCost,
    renderAudioPrice,
    renderFixedPrice,
    isTemplateMessage,
    renderSimpleAudience,
  } = useUtilsCount(isTemplate);


  const handleToggle = () => setToggleprice(!togglePrice);
  const isMultiOperators = NewAudiences.typeAudience === 'MultiOperators';


  const renderTotalPrice = () => {
    if (isCampaign) {
      if (isBillboards || isAudioAds) {
        if (NewOrder.totalPrice > 0) {
          return (<span className={styles.totalPrice}>{showTotalPrice()}</span>);
        }
        return null;
      }
      return (
        <span className={styles.totalPrice}>{showTotalPrice()}</span>
      );
    }
    return (
      <>
        <span className={styles.sumLineText}>{showDefaultPrice()}</span>
        <span className={classNames(styles.price, styles.price1)}>
          {
            !isTSMS ? showTitlePrice() : null
          }
        </span>
      </>
    );
  };

  const getTitle = () => {
    if (titleMain) return titleMain;
    return isCampaign ? 'Расчет стоимости' : 'Стоимость при заведении кампании';
  };

  if (NewOrder.order?.type === 'Sms') {
    return (
      <SmsWidgetPrice
        isCampaign={isCampaign}
      />
    );
  }


  return (
    <>
      {
        !(isTemplate || isBillboards || isAudioAds) && (
          <Card
            elevation="medium"
            className={`${styles.container} ${className}`}
          >
            <TextAudience/>
          </Card>
        )
      }

      {(!isVoiceTarget && !NewAudiences.filterAudienceData.find(item => item.condition.term?.pixelId) && !isMyAudience) &&
      (NewAudiences.isLookAlike ? (NewAudiences.isLookAlike && !window.location.href.includes('/audience')) : true) ?
        <Card
          elevation="medium"
          className={classNames(styles.container, styles.container1, isBillboards && NewOrder.totalPrice > 0 ? null : styles.cont)}
        >
          <p className={classNames(styles.header, styles.header1, !isShowLineAndToggle && styles.correctMargin1)}>
            {getTitle()}
            {isMultiOperators &&
              <PopoverHelper
                title="Расчет стоимости приблизительный"
                body="Окончательный расчет будет предоставлен после согласования у всех операторов"
              >
                <IconButton iconName={Icons.InfoCircled}/>
              </PopoverHelper>
            }
          </p>

          <p className={classNames(styles.sumLine, isCampaign && styles.correctMargin)}>
            {!NewOrder.calculateData?.isFixedPrice ? renderTotalPrice() : (<>
              <span className={styles.sumLineText}>{showDefaultPrice()}</span>
              <span className={classNames(styles.price1)}>{NewOrder.calculateData?.eventCost}</span>
            </>)}
          </p>
          {(isShowLineAndToggle && isCampaign) &&
            <Divider className={styles.line}/>}
          {/* {(isShowLineAndToggle || !isCampaign) && <Divider className={styles.line}/>} */}

          <div
            className={classNames(styles.contentPrice, togglePrice ? styles.togglePriceIconHide : styles.togglePriceIconShow)}>
            {!NewOrder.calculateData?.isFixedPrice && isCampaign &&
              <p className={styles.description}>
                <span>{showDefaultPrice()}</span>
                <span className={styles.totalPriceCampaign}>
                  {
                    !isAudioAds && !isBillboards && !isTSMS ? showTitlePrice() : null
                  }
                </span>
              </p>
            }

            {
              (!isTSMS && !isCampaign) &&
              (!NewAudiences.isSimple) &&
              (NewOrder.order?.type !== 'Push') &&
              (
                !NewOrder.calculateData?.isFixedPrice ? (
                  <p className={styles.description}>
                    <span>{renderBase()}</span>
                    <span className={styles.fixPrice}>{priceBase()}</span>
                  </p>
                ) : (
                  <>
                    {
                      renderFixedPrice()
                    }
                  </>
                )
              )
            }
            {
              !NewAudiences.isSimple &&
              (NewOrder.order?.type === 'Push') &&
              !isCampaign &&
              <p className={styles.description}>
                <span>{renderBase()}</span>
                <span className={styles.fixPrice}>{priceBase()}</span>
              </p>
            }
            {
              NewAudiences.isSimple &&
              (NewOrder.order?.type === 'Push') &&
              !isCampaign &&
              renderSimpleAudience()
            }
            {isCampaign ?
              <>
                {renderTotalCost()}
              </> :
              <>
                {(!isTemplate && !NewOrder.calculateData?.isFixedPrice) && !NewAudiences.isSimple
                  && NewAudiences.renderPriceSegment.map(({ price, title }) => {
                    return (
                      <p
                        key={`${title}-${price}`}
                        className={styles.description}
                      >
                        {title}
                        <span>
                          {formatPriceTotal(price)}
                        </span>
                      </p>
                    );
                  })}
              </>
            }

            {
              NewAudiences.isCustom && (
                <>
                  <Divider className={styles.line}/>

                  <p className={classNames(styles.description)}>
                    Параметры инд. аудитории
                    <span>+{NewAudiences.OTHER_PRICE}</span>
                  </p>

                </>
              )
            }
            {isAudioAds && renderAudioPrice()}

            {
              isBillboards && NewOrder.totalPrice > 0 ? (
                <>
                  <p className={styles.description}>
                    Бюджет
                    <span>{NewOrder.hasBanner ? formatPriceTotal(NewOrder.totalPrice) : formatPriceTotal(NewOrder.totalPrice - PRICE_FOR_BANNER_TEXT)}</span>
                  </p>
                  {
                    !NewOrder.hasBanner ? (
                      <p className={styles.description}>
                        Создание баннера
                        <span>{formatPriceTotal(PRICE_FOR_BANNER_TEXT)}</span>
                      </p>
                    ) : ''
                  }

                </>
              ) : isBillboards && <p className={styles.firstInfo}>Высчитывается исходя из выбранных услуг и бюджета</p>
            }

          </div>
          {
            NewAudiences.isMultiOperators && !isTemplateMessage && (
              <>
                <Divider className={styles.line}/>
                <div className={styles.wrapMultiOp}>
                  <p className={styles.multiOp}>Другие операторы</p>
                  <p className={styles.description}>
                    1 SMS
                    <span className={classNames(styles.price1)}>{renderBaseSmsPriceForOtherOper()}</span>
                  </p>
                  {NewOrder.countMessageOther && !togglePrice && isCampaign ?
                    <p className={styles.description}>
                      Количество
                      <span>
                        {NewOrder.countMessageOther}
                      </span>
                    </p> : null
                  }
                </div>
              </>
            )
          }

          {(isShowLineAndToggle || (!isCampaign && !NewAudiences.isNotRenderPrice)) &&
            <>
              <IconButton
                className={styles.toggleIcon}
                iconName={togglePrice ? Icons.NavArrowDown : Icons.NavArrowUp}
                onClick={handleToggle}
              />
            </>

          }

        </Card> : null
      }

    </>
  );
});

export default WidgetAudience;
