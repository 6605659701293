import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { ContentContainer } from 'redesignSrc/UI/layout';
import { GroupTitle, FormFieldLabel } from 'redesignSrc/UI/typography';
import SelectionInput from 'redesignSrc/UI/form/SelectionInput';
import Constructor from 'store/mobx/Constructor';
import NewOrder from 'store/mobx/NewOrder';
import { CampaignType } from 'store/mobx/NewOrder/type';
import UserInfo from 'store/mobx/UserInfo';
import ContractSelectionSSStore, { TypeBanCustomer } from './ContractSelectionSSStore';
import styles from './styles.pcss';

type TypeIndustry = {
  campaignType: CampaignType;
  id: number;
  isActive: boolean;
  name: string;
};


type Props = {
  validation: any;
  onChange?: (e: TypeBanCustomer) => void,
  customerId?: number | null
};

const ContractSelectionSS: React.FC<Props> = ({ validation, onChange, customerId }) => {
  const [store, _] = useState(new ContractSelectionSSStore());
  const [industry, setIndustry] = useState<TypeIndustry | null>(null);
  const [banId, setBanId] = useState(NewOrder.order?.ban || '');

  useEffect(() => {
    store.getInvoices({ ban: NewOrder.order?.ban, customerId: NewOrder.order?.customerId });
    store.getBans(customerId);
    Constructor.getIndustries(NewOrder.order?.type);
  }, []);

  useEffect(() => {
    if (Constructor.industries.length && NewOrder.order?.industryId) {
      store.isNotEffect = true;
      setIndustry(Constructor.industries.find(el => el.id === NewOrder.order?.industryId) || null);
    }
  }, [Constructor.industries]);

  useEffect(() => {
    (async () => {
      if (store.isNotEffect) return;
      if (store.currentBan && !store.currentInvoice) {
        setBanId(store.currentBan.ban);
        !UserInfo.isAggregator && await store.getInvoices({
          ban: store.currentBan.ban,
          customerId: store.currentBan.customerId,
        });
        await store.setBanForOrder(store.currentBan.companyId);
        onChange && onChange(store.currentBan);
      }
    })();
    store.isNotEffect = false;
  }, [store.currentBan]);


  useEffect(() => {
    (async () => {
      if (store.isNotEffect) return;
      validation.setErrorsOfKey('ban', undefined);
      const currentInvoice = store.currentInvoice;

      if (currentInvoice === null && store.isDelete) {
        await store.setInvoiceForOrder(null);
      }

      if (currentInvoice && !store.currentBan) {
        const currentBan = store.bans.find(el => el.ban === currentInvoice.ban);
        if (currentBan) {
          setIndustry(Constructor.industries.find(el => el.id === currentInvoice.industryId) || null);
          store.currentBan = currentBan;
          setBanId(store.currentBan.ban);
          await store.setBanForOrder(store.currentBan.companyId);
          await store.setIndustryForOrder(currentInvoice.industryId);
          await store.setInvoiceForOrder(currentInvoice.id);
        } else {
          validation.setErrorsOfKey('ban', 'С этим счетом нет связанных банов');
        }
      } else if (currentInvoice && store.currentBan) {
        setIndustry(Constructor.industries.find(el => el.id === currentInvoice.industryId) || null);
        await store.setIndustryForOrder(currentInvoice.industryId);
        await store.setInvoiceForOrder(currentInvoice.id);
      }
    })();
    store.isNotEffect = false;
  }, [store.currentInvoice]);


  useEffect(() => {
    (async () => {
      if (store.isNotEffect) return;
      if (industry === null && store.isDelete) {
        await store.setIndustryForOrder(null);
      }
      if (industry && !store.currentInvoice) {
        await store.getInvoices({ industryId: industry.id });
        await store.setIndustryForOrder(industry.id);
      }
    })();
    store.isNotEffect = false;
  }, [industry]);


  return (
    <ContentContainer className={styles.container}>
      <GroupTitle className={styles.blockTitle}>Договор</GroupTitle>
      <p className={styles.description}>
        {UserInfo.isRoleSS ?
          'Выбирайте номер договора, для которого хотите запустить кампанию и единый счет, если необходимо' :
          'Выбирайте отрасль, под которую подходит контент вашей рекламной кампании и, если их несколько, договор'
        }

      </p>

      <div className={styles.wrapperInput}>
        {/* Поле выбора Bans */}
        <div>
          <FormFieldLabel>Номер договора</FormFieldLabel>
          <SelectionInput
            placeholder={store.bans.length === 0 ? 'Будет создан после запуска кампании' : ''}
            value={banId}
            validation={validation}
            name="ban"
            disabled={store.isDisableBan}
          >
            <div className={styles.containerList}>
              {store.bans.map((opt) => {
                return (
                  <div
                    className={styles.listElement}
                    onClick={() => {
                      validation.setErrorsOfKey('ban', undefined);
                      store.currentBan = opt;
                      store.currentInvoice = null;
                      store.isDelete = true;
                    }}
                  >
                    <div>{`№ ${opt.ban}`}</div>
                    <div className={styles.secondary}>{`ID ${opt.customerId}, ${
                      opt.customerName
                    }, ИНН ${opt.customerInn}${opt.zone ? `, ${opt.zone}` : ''}`}</div>
                  </div>
                );
              })}
            </div>
          </SelectionInput>
          {store.currentBan &&
            <div className={styles.info}>
              {`ID ${store.currentBan.customerId}, ${store.currentBan.customerName},
            ИНН ${store.currentBan.customerInn}${store.currentBan.zone ? `, ${store.currentBan.zone}` : ''}`}
            </div>}
        </div>

        {/* Поле выбора Invoices */}
        {UserInfo.isRoleSS &&
          <div>
            <FormFieldLabel>Единый счет</FormFieldLabel>
            <SelectionInput
              value={`${store.currentInvoice?.id || ''}`}
              disabled={store.isUpdateInvoices}
            >
              <div className={styles.containerList}>
                {store.invoices.length === 0 ?
                  <div className={styles.listElement}>
                    Нет доступных счетов
                  </div> :
                  <div
                    className={styles.listElement}
                    onClick={() => {
                      store.isDelete = true;
                      store.currentInvoice = null;
                    }}
                  >
                    Не выбрано
                  </div>
                }

                {store.invoices.map((el) => {
                  return (
                    <div
                      className={styles.listElement}
                      onClick={() => {
                        store.currentInvoice = el;
                      }}
                    >
                      <div>{el.name}</div>
                      <div className={styles.secondary}>{`${el.id} (${
                        el.isAutoInvoice ? 'автоматический' : 'ручной'
                      })`}</div>
                    </div>
                  );
                })}
              </div>
            </SelectionInput>
          </div>
        }

        {/* Поле выбора Industry */}
        <div>
          <FormFieldLabel>Отрасль</FormFieldLabel>
          <SelectionInput
            value={`${industry?.name || ''}`}
            validation={validation}
            name="industryId"
          >
            <div className={styles.containerList}>
              {!store.currentInvoice && <div
                className={styles.listElement}
                onClick={() => {
                  store.isDelete = true;
                  setIndustry(null);
                }}
              >
                Не выбрано
              </div>}
              {store.industries.map((el: TypeIndustry) => {
                return (
                  <div
                    className={styles.listElement}
                    onClick={() => {
                      setIndustry(el);
                      validation.setErrorsOfKey('industryId', undefined);
                    }}
                  >
                    <div>{el.name}</div>
                  </div>
                );
              })}
            </div>
          </SelectionInput>
        </div>

      </div>
    </ContentContainer>
  );
};

export default observer(ContractSelectionSS);
