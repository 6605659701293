import { Button, FileUploader, Icon, TextArea } from '@beeline/design-system-react';
import React, { useContext, useState } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import { DocsByBusinessList, senderNameStore } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';

import { toastErrorNotification } from 'modules/toast-notifications';
import UserInfo from 'store/mobx/UserInfo';
import SelectField from '../../SmsPages/SmsPageSetup/components/SenderName/CreateSenderName/components/SelectField';
import { CreateSenderName, SenderNameContext, SenderNameContextType } from '../../SmsPages/SmsPageSetup/components/SenderName';
import { scrollSmoothToNodeById } from '../../../../utils/scroll';
import styles from './styles.pcss';
import UploadedFiles from './components/UploadedFiles';
import SenderNameText from './components/SenderNameText';
import SwitchField from './components/SwitchField';
import SenderNamesFileList from './components/SenderNameList';
import ConfirmationDialog from './components/ConfirmationDialog';
import InfoDialog from './components/InfoDialog';

const cx = classNames.bind(styles);

const CreateSenderNameBody = observer(() => {
  const senderContext = useContext<SenderNameContextType | null>(SenderNameContext);
  if (!senderContext) {
    throw new Error('senderContext не найден');
  }
  const {
    validation,
    senderNameId,
    isIp = false,
    setIsIp,
    onAddFiles,
    chooseDocsIsVisible,
    isInfoVisited,
    setIsInfoVisited,
    onReplaceFile,
    onDeleteFile,
    onFileEdit,
    checkIfSenderNameExist,
  } = senderContext;
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const sendFormData = async (values: { senderName: string, comment?: string }) => {
    if (!values.senderName) return null;
    senderNameStore.loading = true;
    let nameId = '';
    if (!senderNameId) {
      nameId = await senderNameStore.createName(values.senderName);
      senderNameStore.senderId = +nameId;
    } else {
      nameId = senderNameId;
    }
    if (nameId) {
      await senderNameStore.sendAllFiles(nameId);
    }
    if (values.comment) {
      await senderNameStore.sendComment(nameId, values.comment);
    }
    await senderNameStore.getSenderNameById(+nameId);
    await senderNameStore.getAllSenderNames();
  };

  return (
    <form
      className={styles.form}
      onSubmit={async (e) => {
        e.preventDefault();
        try {
          await sendFormData(senderNameStore.form as { senderName: string, comment?: string });
          await navigate('/sender-names/');
        } catch (error) {
          console.error(error);
          toastErrorNotification('Во время выполнения запроса произошла ошибка');
        } finally {
          senderNameStore.loading = false;
        }
      }}
    >
      <TypographyWithDangling
        variant="h1"
        className={styles.title}
      >
        Создание имени отправителя
      </TypographyWithDangling>
      <div className={styles.whiteBlock}>
        <div className={cx(styles.mainTitle, styles.mb29)}>
          Придумайте имя
          {
            !validation.errors?.senderName &&
            (senderNameStore.form?.senderName as string)?.length &&
            <span>
              <Icon iconName={Icons.CheckCircled} color="green" />
              </span> ||
            null
          }
        </div>
        <SwitchField
          value={isIp}
          onChange={setIsIp}
        />
        <div id="senderNameField" />
        <SenderNameText
          error={!!validation.errors?.senderName}
          currentLength={(senderNameStore?.form?.senderName as string)?.length || 0}
          onChange={(e) => {
            senderNameStore.form.senderName = e.currentTarget.value;
          }}
          value={senderNameStore?.form?.senderName as string || ''}
          helperText={validation.errors?.senderName || 'Только латинские буквы, цифры и символы «-», «_», «.».'}
        />
        {
          isIp &&
          <div className={styles.attention}>
            <Icon type="warning" iconName={Icons.WarningCircled} contained/>
            <TypographyWithDangling variant="body3">
              Для ИП имя отправителя должно отличаться от наименования организации
            </TypographyWithDangling>
          </div>
        }
      </div>

      <div className={styles.whiteBlock}>
        <div className={cx(styles.mainTitle, styles.mb16)}>
          Загрузите любой документ из списка
          {
            Object.entries(senderNameStore.userFilesById || {}).length &&
            <span>
              <Icon iconName={Icons.CheckCircled} color="green" />
              </span> || null
          }
        </div>
        <div className={styles.fileAttention}>
          <Icon iconName={Icons.WarningCircled} contained/>
          <TypographyWithDangling variant="body3" className={styles.naming}>
            Выберите из списка и загрузите тип документа, который у вас есть. Если документ состоит из нескольких файлов, назовите каждый файл понятно: например, стр. 1, стр 2 и т.д
          </TypographyWithDangling>
        </div>

        {
          Object.entries(senderNameStore.userFilesById || {}).length &&
          Object.entries(senderNameStore.userFilesById || {}).map(([key, value]) => {
            return (
              <UploadedFiles
                key={value.length}
                title={value[0]?.documentTypeName}
                onAddFiles={(e, docTypeId) => {
                  if (e.target.files) {
                    onAddFiles(e.target.files, docTypeId);
                  }
                }}
                documentTypeId={+key}
              >
                <SenderNamesFileList
                  onReplaceFile={onReplaceFile}
                  onDeleteFile={onDeleteFile}
                  title={value?.[0]?.documentTypeName || ''}
                  onFileEdit={onFileEdit}
                  docs={value}
                />
              </UploadedFiles>
            );
          }) || null
        }

        <SelectField
          type={isIp ? 1 : 0}
          fetchDocsList={senderNameStore.fetchDocsByBusinessType}
          docsByBusinessType={senderNameStore.docsByBusinessType}
          currentValues={senderNameStore?.form?.documentType as DocsByBusinessList[] || []}
          onChange={(e) => {
            senderNameStore.form.documentType = e;
          }}
          error={!senderNameStore?.form?.documentType ? validation?.errors?.files : ''}
        />
        {
          (senderNameStore?.form?.documentType as { value: string }[])?.[0]?.value === 'Другой документ, разрешающий использовать имя' &&
          chooseDocsIsVisible &&
          <div
            className={cx(styles.pseudoLink, isInfoVisited && styles.visited)}
            onClick={() => {
              setIsInfoDialogOpen(true);
              setIsInfoVisited(true);
            }}
          >
            <Icon contained={false} iconName={Icons.InfoCircled} color={isInfoVisited ? 'purple' : 'blue'} />
            Как быстро согласовать имя и какие документы не подойдут?
          </div> || null
        }

        {
          (senderNameStore?.form?.documentType as DocsByBusinessList[]) &&
          <FileUploader
            title="Перетащите сюда файлы или"
            linkName="загрузите документы"
            subTitle="pdf, jpg, png, gif, rar, zip до 20 Мб"
            multiple={false}
            error={!!validation?.errors?.files}
            helperText={validation?.errors?.files || ''}
            onChange={(e) => {
              if (e.target.files) {
                onAddFiles(e.target.files);
                validation.resetError();
              }
            }}
            className={styles.filesDrop}
            onDrop={(e) => e?.dataTransfer?.files && onAddFiles(e.dataTransfer.files as FileList)}
          /> || null
        }
        {
          Object.entries(senderNameStore.userFilesById || {}).length &&
          !senderNameStore?.form?.documentType &&
          <Button
            startIcon={<Icon iconName={Icons.Add} />}
            variant="overlay"
            className={styles.addMore}
            type="button"
          >
            Добавить документ
          </Button> || null
        }
      </div>
      {
        Object.entries(senderNameStore.userFilesById || {}).length &&
        <div className={styles.whiteBlock}>
          <div className={cx(styles.mainTitle, styles.mb29)}>
            Комментарий
          </div>
          <TextArea
            onChange={(e) => {
              (senderNameStore.form as { comment: string }).comment = e.currentTarget.value;
            }}
            className={styles.comment}
            placeholder="Укажите любой комментарий, если нужно"
          />
        </div> || null
      }

      <div className={styles.btnsHolder}>
        <Button
          disabled={senderNameStore.loading}
          variant="outlined"
          type="submit"
          className={styles.sendBtn}
        >
          Сохранить в черновик
        </Button>
        <Button
          variant="contained"
          disabled={senderNameStore.loading}
          type="button"
          className={styles.sendBtn}
          onClick={async () => {
            try {
              if (validation.getAllValid().firstErrorKey) {
                console.error(validation.errors);
                return;
              }
              const numberSender = await checkIfSenderNameExist(senderNameStore.form.senderName as string, UserInfo?.data?.company?.ban ? +UserInfo?.data?.company?.ban : undefined);
              if (numberSender) {
                validation.setErrorsOfKey('senderName', 'Такое имя уже существует');
                scrollSmoothToNodeById('senderNameField', 200);
              }
            } catch (error) {
              if (error?.response?.status === 404) {
                  await sendFormData(senderNameStore.form as { senderName: string, comment?: string });
                  senderNameStore.agreementModal = true;
              }
            }
          }}
        >
          Отправить на согласование
        </Button>
      </div>
      <ConfirmationDialog
        sendFormData={async () => {
          await sendFormData(senderNameStore.form as { senderName: string, comment?: string });
        }} />
      {
        isInfoDialogOpen &&
        <InfoDialog onClose={() => setIsInfoDialogOpen(false)} />
      }
    </form>
  );
});

const CreateSenderNameCard = () => {
  return (
    <CreateSenderName>
      <CreateSenderNameBody />
    </CreateSenderName>
  );
};

export default CreateSenderNameCard;


// export const EditSenderName = observer(() => {
//   const { id } = useParams();
//   const [isLoading, setIsLoading] = useState(true);
//   useEffect(() => {
//     (async () => {
//       try {
//         if (!id) {
//           setIsLoading(false);
//           return;
//         }
//         senderNameStore.senderId = id;
//         await senderNameStore.getSenderNameByIdSection(id);
//         await senderNameStore.getAllFilesBySenderIdSection();
//         await senderNameStore.getAllComments(id);
//       } catch (e) {
//         toastErrorNotification('Ошибка при загрузке имени отправителя');
//       } finally {
//         setIsLoading(false);
//       }
//     })();
//   }, [id]);
//   return (
//     <PageLoader isLoading={isLoading}>
//       <CreateSenderName senderNameId={id} />
//     </PageLoader>
//   );
// });
